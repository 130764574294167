import React, { useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import "../public/index.css";
import { Navigation } from "./components/Navigation";
import { Home } from "./components/Home";
import { Technologies } from "./components/Technologies";
import { Products } from "./components/Products";
import { Contact } from "./components/Contact";
import { Cookies } from "./components/Cookies";
import { LngProvider, useLng } from "./context/LngProvider";
import { NotFound } from "./components/NotFound";
import { handleUrlChange } from "./helpers/helpers";
import { translations } from "./assets/translations";

const App = () => {
  const { setLng } = useLng();
  const history = useHistory();
  const { pathname } = useLocation();
  useEffect(() => {
    (() => {
      axios
        .get("https://ipapi.co/json/")
        .then(({ data }) => {
          let { country } = data;

          if (country === "CH" || country === "AT" || country === "DE") {
            country = "de";
          } else if (country === "CZ" || country === "SK") {
            country = "sk";
          } else {
            country = "en";
          }
          handleUrlChange(pathname, country, setLng, history, translations);
        })
        .catch((err) => {
          console.error(err);
          return;
        });
    })();
  }, []);

  return (
    <div className="app">
      <div className="app-header">
        <Navigation />
      </div>
      <div className="app-body">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/:lang">
            <Home />
          </Route>
          <Route
            exact
            path={[
              "/:lang/technologie",
              "/:lang/technologien",
              "/:lang/technologies",
            ]}
          >
            <Technologies />
          </Route>
          <Route
            exact
            path={["/:lang/produkty", "/:lang/produkte", "/:lang/products"]}
          >
            <Products />
          </Route>
          <Route
            exact
            path={["/:lang/kontakt", "/:lang/impressum", "/:lang/contact"]}
          >
            <Contact />
          </Route>
          <Route exact path="/:lang/cookies">
            <Cookies />
          </Route>
          <Route path="/:lang/*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

if (process.env.NODE_ENV !== "production") {
  console.log("Looks like we are in development mode!");
}

ReactDOM.render(
  <LngProvider>
    <Router>
      <App />
    </Router>
  </LngProvider>,
  document.getElementById("root")
);
