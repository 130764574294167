module.exports = {
  frezovanie: [
    {
      name: {
        sk: "Hermle C 250",
        en: "Hermle C 250",
        de: "Hermle C 250",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1670751474/kovovelic/machines/hermle-c250.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1670751474/kovovelic/machines/hermle-c250.png",
    },
    {
      name: {
        sk: "Hermle C400",
        en: "Hermle C400",
        de: "Hermle C400",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1552509044/kovovelic/machines/Frezovanie_Hermle_C400-1.jpg.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1552509044/kovovelic/machines/Frezovanie_Hermle_C400-1.jpg.jpg",
    },
    {
      name: {
        sk: "Quaser 184 P",
        en: "Quaser 184 P",
        de: "Quaser 184 P",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1670789176/kovovelic/machines/quaser-184p.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1670789176/kovovelic/machines/quaser-184p.png",
    },
    {
      name: {
        sk: "Quaser MV 184P",
        en: "Quaser MV 184P",
        de: "Quaser MV 184P",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1550350906/kovovelic/machines/Fr%C3%A9zovanie_Quaser_MV_184P.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1550350906/kovovelic/machines/Fr%C3%A9zovanie_Quaser_MV_184P.jpg",
    },
    {
      name: {
        sk: "Leadwell V50L",
        en: "Leadwell V50L",
        de: "Leadwell V50L",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1543732269/kovovelic/machines/Frezovanie_Leadwell_V50-L.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732269/kovovelic/machines/Frezovanie_Leadwell_V50-L.jpg",
    },
    // {
    //   name: {
    //     sk: "Tongtai VMC 850",
    //     en: "Tongtai VMC 850",
    //     de: "Tongtai VMC 850",
    //   },
    //   pic:
    //     "https://res.cloudinary.com/threesix/image/upload/v1543732269/kovovelic/machines/Frezovanie_TongTai_VMC_850.jpg",
    //   smallPic:
    //     "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732269/kovovelic/machines/Frezovanie_TongTai_VMC_850.jpg",
    // },
    {
      name: {
        sk: "Dugard Eagle 1000",
        en: "Dugard Eagle 1000",
        de: "Dugard Eagle 1000",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1546201812/kovovelic/machines/Frezovanie_Dugard_Eagle_1000.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1546201812/kovovelic/machines/Frezovanie_Dugard_Eagle_1000.png",
    },
  ],
  sustruzenie: [
    {
      name: {
        sk: "Hyundai L230 LMA",
        en: "Hyundai L230 LMA",
        de: "Hyundai L230 LMA",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1670751475/kovovelic/machines/hyundai-l230lma.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1670751475/kovovelic/machines/hyundai-l230lma.png",
    },
    {
      name: {
        sk: "MAS SP280",
        en: "MAS SP280",
        de: "MAS SP280",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1543732274/kovovelic/machines/Sustruzenie_MAS_Kovosvit_SP_280_MC.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732274/kovovelic/machines/Sustruzenie_MAS_Kovosvit_SP_280_MC.jpg",
    },
    {
      name: {
        sk: "Hwacheon HI TECH 200AL",
        en: "Hwacheon HI TECH 200AL",
        de: "Hwacheon HI TECH 200AL",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1545086312/kovovelic/machines/Sustruzenie-Hwacheon_HI-TECH200AL.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1545086312/kovovelic/machines/Sustruzenie-Hwacheon_HI-TECH200AL.jpg",
    },
    {
      name: {
        sk: "Trens SE520",
        en: "Trens SE520",
        de: "Trens SE520",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1545086158/kovovelic/machines/Sustruzeni__Trens_SE_520_NC.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1545086158/kovovelic/machines/Sustruzeni__Trens_SE_520_NC.jpg",
    },
    {
      name: {
        sk: "SV 18RP",
        en: "SV 18RP",
        de: "SV 18RP",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1546202250/kovovelic/machines/SV_18_RP.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1546202250/kovovelic/machines/SV_18_RP.png",
    },
  ],
  brusenie: [
    {
      name: {
        sk: "Studer S33",
        en: "Studer S33",
        de: "Studer S33",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1645601570/kovovelic/machines/Studer_S33.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1645601570/kovovelic/machines/Studer_S33.jpg",
    },
    {
      name: {
        sk: "Hauser",
        en: "Hauser",
        de: "Hauser",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1545086011/kovovelic/machines/2x-tento-stroj-Brusenie_Hauser.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1545086011/kovovelic/machines/2x-tento-stroj-Brusenie_Hauser.png",
    },
    {
      name: {
        sk: "BHU 32",
        en: "BHU 32",
        de: "BHU 32",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1546201810/kovovelic/machines/Brusenie_BHU_32.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1546201810/kovovelic/machines/Brusenie_BHU_32.png",
    },
    {
      name: {
        sk: "BPH 20 NA",
        en: "BPH 20 NA",
        de: "BPH 20 NA",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1546201808/kovovelic/machines/Brusenie_BPH_20_NA.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1546201808/kovovelic/machines/Brusenie_BPH_20_NA.png",
    },
    {
      name: {
        sk: "BRH 20.02",
        en: "BRH 20.02",
        de: "BRH 20.02",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1550353065/kovovelic/machines/Br%C3%BAsenie_BRH_20.02.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1550353065/kovovelic/machines/Br%C3%BAsenie_BRH_20.02.png",
    },
    {
      name: {
        sk: "BU 25H",
        en: "BU 25H",
        de: "BU 25H",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1552508393/kovovelic/machines/Br%C3%BAsenie__BU_25H.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1552508393/kovovelic/machines/Br%C3%BAsenie__BU_25H.png",
    },
    {
      name: {
        sk: "BRH 40x1500",
        en: "BRH 40x1500",
        de: "BRH 40x1500",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1550353064/kovovelic/machines/Bru%CC%81senie_BRH_40x1500.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1550353064/kovovelic/machines/Bru%CC%81senie_BRH_40x1500.png",
    },
  ],
  edm: [
    {
      name: {
        sk: "Exeron 314",
        en: "Exeron 314",
        de: "Exeron 314",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1706363657/kovovelic/machines/Exeron_EDM_314-removebg-preview.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1706363657/kovovelic/machines/Exeron_EDM_314-removebg-preview.png",
    },
  ],
  ostatne: [
    {
      name: {
        sk: "Píla Pegas Gonda Herkules",
        en: "Sawmill Pegas Gonda Herkules",
        de: "Sägewerk Pegas Gonda Herkules",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1543732273/kovovelic/machines/Pila_Pegas_Gonda_Herkules.png",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732273/kovovelic/machines/Pila_Pegas_Gonda_Herkules.png",
    },
    {
      name: {
        sk: "Závitorez Tiger",
        en: "Threading machine Tiger",
        de: "Gewindeschneidmaschine Tiger",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1543732274/kovovelic/machines/Zavitovanie_Zavitorez_Tiger.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732274/kovovelic/machines/Zavitovanie_Zavitorez_Tiger.jpg",
    },
    {
      name: {
        sk: "Laser Baublys",
        en: "Laser Baublys",
        de: "Laser Baublys",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1543732272/kovovelic/machines/Laser_Baublys.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732272/kovovelic/machines/Laser_Baublys.jpg",
    },
    {
      name: {
        sk: "Meranie Mitutoyo LH 600",
        en: "Measurement Mitutoyo LH 600",
        de: "Messung Mitutoyo LH 600",
      },
      pic:
        "https://res.cloudinary.com/threesix/image/upload/v1543732274/kovovelic/machines/Meranie_Mitutoyo_LH_600.jpg",
      smallPic:
        "https://res.cloudinary.com/threesix/image/upload/c_scale,w_500/v1543732274/kovovelic/machines/Meranie_Mitutoyo_LH_600.jpg",
    },
  ],
};
