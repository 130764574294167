import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import ImageBox from "./ImageBox";
import { ProductCarousel } from "./ProductCarousel";
import isoBadge from "../../public/img/iso.png";
import cemsBadge from "../../public/img/cems.png";
import certSK from "../../public/img/ISO 9001 SK.png";
import certDE from "../../public/img/ISO 9001 DE.png";
import certEN from "../../public/img/ISO 9001 EN.png";
import { translations } from "../assets/translations";
import { languages } from "../assets/languages";
import { useLng } from "../context/LngProvider";
import { Helmet } from "react-helmet";

export const Home = () => {
  const { lang } = useParams();
  const { lng, setLng } = useLng();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRedirect(false);
    if (lang !== lng && languages.indexOf(lang) >= 0) {
      setLng(lang);
    }
    if (lang !== lng && languages.indexOf(lang) < 0) {
      setLng("sk");
      setRedirect(true);
    }
  }, [lang]);

  const [state, setState] = useState({
    showImageBox: false,
    showUrl: "",
    certs: {
      sk:
        // "https://res.cloudinary.com/threesix/image/upload/v1588440266/kovovelic/certs/Certifikat_KOVO_Velic_QMS01819_SK.png",
        certSK,
      de:
        // "https://res.cloudinary.com/threesix/image/upload/v1588440266/kovovelic/certs/Certifikat_KOVO_Velic_QMS01819_DE.png",
        certDE,
      en:
        // "https://res.cloudinary.com/threesix/image/upload/v1588440266/kovovelic/certs/Certifikat_KOVO_Velic_QMS01819_EN.png",
        certEN,
    },
  });

  const handleImageBox = (open, url) => {
    let newState = Object.assign({}, state);
    newState.showImageBox = open;
    newState.showUrl = url;
    setState(newState);
  };

  if (redirect) return <Redirect to="/sk" />;

  return (
    <div id="Home">
      <Helmet>
        <title>KOVO Velič</title>
      </Helmet>
      <p>
        {(translations &&
          translations[lng] &&
          translations[lng][
            "Firma Kovo Velič vznikla v roku 1995. Sme rodinná firma zameraná na presnú strojársku výrobu. V súčastnosti zamestnávame 27 zamestnancov."
          ]) ||
          "Firma Kovo Velič vznikla v roku 1995. Sme rodinná firma zameraná na presnú strojársku výrobu. V súčastnosti zamestnávame 27 zamestnancov."}
      </p>
      <p>
        {(translations &&
          translations[lng] &&
          translations[lng][
            "Výrobný program našej firmy je zameraný na kusovú a malosériovú výrobu upínacieho náradia, meradiel a súčiastok podľa požiadaviek zákazníka. V záujme zvyšovania konkurencieschopnosti, neustále obnovujeme náš strojný park najmodernejšími technológiami. Poskytujeme služby v oblasti CNC frézovania, sústruženia, brúsenia ozubenia, disponujeme viacerymi brúskami na plocho, guľato a taktiež dvomi súradnicovými brúskami Hauser."
          ]) ||
          "Výrobný program našej firmy je zameraný na kusovú a malosériovú výrobu upínacieho náradia, meradiel a súčiastok podľa požiadaviek zákazníka. V záujme zvyšovania konkurencieschopnosti, neustále obnovujeme náš strojný park najmodernejšími technológiami. Poskytujeme služby v oblasti CNC frézovania, sústruženia, brúsenia ozubenia, disponujeme viacerymi brúskami na plocho, guľato a taktiež dvomi súradnicovými brúskami Hauser."}
      </p>
      <ProductCarousel />

      <video
        controls
        className="homePic"
        autoPlay={false}
        src="https://res.cloudinary.com/threesix/video/upload/v1588438243/kovovelic/video/Teleso_vreten%C3%ADka.mov"
        type="video/mov"
        width="80%"
      >
        {(translations &&
          translations[lng] &&
          translations[lng][
            "Je nám to ľúto ale váš prehliadač nepodporuje prehrávanie videa. Nevadí, video si môžete"
          ]) ||
          "Je nám to ľúto ale váš prehliadač nepodporuje prehrávanie videa. Nevadí, video si môžete"}
        <a
          href="https://res.cloudinary.com/threesix/video/upload/v1588438243/kovovelic/video/Teleso_vreten%C3%ADka.mov"
          target="_blank"
        >
          {(translations &&
            translations[lng] &&
            translations[lng][" stiahnuť tu "]) ||
            " stiahnuť tu "}
        </a>
        {(translations &&
          translations[lng] &&
          translations[lng]["a spustiť vo svojom obľúbenom prehliadači."]) ||
          "a spustiť vo svojom obľúbenom prehliadači."}
      </video>
      <h2 className="homeHeader">
        {(translations &&
          translations[lng] &&
          translations[lng]["Certifikát kvality ISO 9001"]) ||
          "Certifikát kvality ISO 9001"}
      </h2>
      <div className="homeCerts">
        <img
          src={state.certs[lang]}
          width="100%"
          alt={
            (translations &&
              translations[lang] &&
              translations[lang]["certifikát kvality"]) ||
            "certifikát kvality"
          }
          onClick={() => {
            handleImageBox(true, state.certs[lang]);
          }}
        />
      </div>
      {/* <div className="homeCertBadges">
        <img src={isoBadge} alt="logo iso 9001"></img>
        <img src={cemsBadge} alt="logo cems"></img>
      </div> */}
      <div className="autonews">
        <h2 className="homeHeader">
          {(translations &&
            translations[lng] &&
            translations[lng]["Vo svete automotive"]) ||
            "Vo svete automotive"}
        </h2>
        <iframe
          src="https://www.autonews.io/embed/news?ref=kovovelic"
          scrolling="yes"
          frameborder="0"
          width="100%"
          height="600"
          style={{ border: "0" }}
        ></iframe>
      </div>
      <ImageBox
        show={state.showImageBox}
        onHide={() => handleImageBox(false, "")}
        handleImageBox={handleImageBox}
        url={state.showUrl}
      />
    </div>
  );
};
