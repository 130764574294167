import React from "react";
import { Navbar, Nav, MenuItem, ButtonGroup, Button } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { useHistory, useLocation } from "react-router-dom";

import logo_screen from "../../public/img/logo.png";
import { translations } from "../assets/translations.js";
import { languages } from "../assets/languages.js";
import { useLng } from "../context/LngProvider";
import { handleUrlChange } from "../helpers/helpers";

export const Navigation = () => {
  const history = useHistory();
  const { lng, setLng } = useLng();
  const { pathname } = useLocation();

  return (
    <Navbar inverse collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <div
            className="logo-position-screen"
            onClick={() => history.push(`/${lng}`)}
          >
            <img
              src={logo_screen}
              className="logo-screen"
              alt="KOVO Velič logo"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          <MenuItem
            eventKey={1}
            title={translations[lng]["O nás"]}
            onSelect={() => history.push(`/${lng}`)}
          >
            {translations[lng]["O nás"] || "O nás"}
          </MenuItem>

          <MenuItem
            eventKey={2}
            title={translations[lng]["Technológie"]}
            onSelect={() =>
              history.push(`/${lng}/${translations[lng]["technologie"]}`)
            }
          >
            {translations[lng]["Technológie"] || "Technológie"}
          </MenuItem>

          <MenuItem
            eventKey={3}
            title={translations[lng]["Produkty"] || "Produkty"}
            onSelect={() =>
              history.push(`/${lng}/${translations[lng]["produkty"]}`)
            }
          >
            {translations[lng]["Produkty"] || "Produkty"}
          </MenuItem>

          <MenuItem
            eventKey={4}
            title={translations[lng]["Kontakt"] || "Kontakt"}
            onSelect={() =>
              history.push(`/${lng}/${translations[lng]["kontakt"]}`)
            }
          >
            {translations[lng]["Kontakt"] || "Kontakt"}
          </MenuItem>

          <CountrySelector
            lng={lng}
            lngs={languages}
            setLng={setLng}
            pathname={pathname}
            history={history}
          />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const CountrySelector = ({ lng, lngs, setLng, pathname, history }) => (
  <ButtonGroup className="countrySelector">
    {lngs
      .filter((countryCode) => countryCode !== lng)
      .map((countryCode, index) => (
        <Button
          type="button"
          value={countryCode}
          key={`${countryCode}-${index}`}
          onClick={() => {
            handleUrlChange(
              pathname,
              countryCode,
              setLng,
              history,
              translations
            );
          }}
        >
          <ReactCountryFlag
            style={{ width: 25, height: 25 }}
            svg
            countryCode={
              countryCode === "en" ? "GB" : countryCode.toUpperCase()
            }
          />
        </Button>
      ))}
  </ButtonGroup>
);
