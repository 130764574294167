module.exports = [
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732270/kovovelic/products/Brusenie_Hauser2.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732529/kovovelic/products/UNADJUSTEDNONRAW_thumb_61d.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732529/kovovelic/products/UNADJUSTEDNONRAW_thumb_4e4.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732529/kovovelic/products/UNADJUSTEDNONRAW_thumb_522.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_E7816.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732268/kovovelic/products/Brusenie_Hauser3.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732528/kovovelic/products/UNADJUSTEDNONRAW_thumb_5e0.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732528/kovovelic/products/UNADJUSTEDNONRAW_thumb_4e1.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732529/kovovelic/products/UNADJUSTEDNONRAW_thumb_43f.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732529/kovovelic/products/UNADJUSTEDNONRAW_thumb_439.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1543732529/kovovelic/products/UNADJUSTEDNONRAW_thumb_612.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_6992.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/8D3E01AE-AEF5-4109-B2C9-BEE11C3923F7.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_6822.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_5335.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_6823.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_7190.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_6688.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_7157.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_7187.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317759/kovovelic/products/IMG_7189.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_7591.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_7644.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_7709.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_7191.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_8136.jpg",
  "https://res.cloudinary.com/threesix/image/upload/c_scale,w_485/v1590317760/kovovelic/products/IMG_8135.jpg",
];
