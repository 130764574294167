import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";

const ImageBox = ({ url, show, onHide, handleImageBox }) => (
  <Modal
    id="ImageBox"
    show={show}
    onHide={onHide}
    dialogClassName="machineImageBox"
  >
    <Modal.Body close>
      <div className="imageBoxHeader">
        <CloseIcon
          onClick={() => {
            handleImageBox(false, "");
          }}
        />
      </div>
      <img
        src={url}
        width="100%"
        onClick={() => {
          handleImageBox(false, "");
        }}
      />
    </Modal.Body>
  </Modal>
);

export default ImageBox;
