import React from "react";

import { useLng } from "../context/LngProvider";
import { translations } from "../assets/translations";

const KEY = {
  frezovanie: "Frézovanie",
  sustruzenie: "Sústruženie",
  brusenie: "Brúsenie",
  edm: "EDM",
  ostatne: "Ostatné",
};

const TechNav = ({ tech, setTech }) => {
  const { lng } = useLng();

  return (
    <div id="TechNav">
      <ul>
        <li className={tech === "frezovanie" ? "techHighlight" : ""}>
          <button onClick={() => setTech("frezovanie")}>
            {(translations &&
              translations[lng] &&
              translations[lng][KEY.frezovanie]) ||
              KEY.frezovanie}
          </button>
        </li>
        <li className={tech === "sustruzenie" ? "techHighlight" : ""}>
          <button onClick={() => setTech("sustruzenie")}>
            {(translations &&
              translations[lng] &&
              translations[lng][KEY.sustruzenie]) ||
              KEY.sustruzenie}
          </button>
        </li>
        <li className={tech === "brusenie" ? "techHighlight" : ""}>
          <button onClick={() => setTech("brusenie")}>
            {(translations &&
              translations[lng] &&
              translations[lng][KEY.brusenie]) ||
              KEY.brusenie}
          </button>
        </li>
        <li className={tech === "edm" ? "techHighlight" : ""}>
          <button onClick={() => setTech("edm")}>
            {(translations &&
              translations[lng] &&
              translations[lng][KEY.edm]) ||
              KEY.edm}
          </button>
        </li>
        <li className={tech === "ostatne" ? "techHighlight" : ""}>
          <button onClick={() => setTech("ostatne")}>
            {(translations &&
              translations[lng] &&
              translations[lng][KEY.ostatne]) ||
              KEY.ostatne}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TechNav;
