import React, { useState, useContext, createContext } from "react";

const LngContext = createContext({
  lng: "sk",
});

// hook
export const useLng = () => {
  return useContext(LngContext);
};

// provider
export const LngProvider = ({ children }) => {
  const lng = useProvideLng();
  return <LngContext.Provider value={lng}>{children}</LngContext.Provider>;
};

const useProvideLng = () => {
  const [lng, setLng] = useState("sk");

  return {
    lng,
    setLng,
  };
};
