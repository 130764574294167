import React, { Fragment } from "react";
import { Redirect, useParams } from "react-router-dom";

export const NotFound = () => {
  const { lang } = useParams();

  return (
    <Fragment>
      {lang && <Redirect to={`/${lang}`} />}
      {!lang && <Redirect to="/sk" />}
    </Fragment>
  );
};
