const sortByDateDesc = (array, date = "date") => {
  return array.sort(function(a, b) {
    return b[date] > a[date] ? 1 : b[date] < a[date] ? -1 : 0;
  });
};

const sortByDateAsc = (array, date = "date") => {
  return array.sort(function(a, b) {
    return a[date] > b[date] ? 1 : a[date] < b[date] ? -1 : 0;
  });
};

const handleUrlChange = (pathname, lng, setLng, history, translations) => {
  let split = pathname.split("/");

  if (split.length === 2) {
    split[1] = lng;
  }

  if (split.length === 3) {
    split[1] = lng;
    if (
      split[2] === "produkty" ||
      split[2] === "produkte" ||
      split[2] === "products"
    ) {
      split[2] = translations[lng]["produkty"];
    }
    if (
      split[2] === "kontakt" ||
      split[2] === "impressum" ||
      split[2] === "contact"
    ) {
      split[2] = translations[lng]["kontakt"];
    }
    if (
      split[2] === "technologie" ||
      split[2] === "technologien" ||
      split[2] === "technologies"
    ) {
      split[2] = translations[lng]["technologie"];
    }
  }

  setLng(lng);
  history.push(`${split.join("/")}`);
};

export { sortByDateDesc, sortByDateAsc, handleUrlChange };
