import React, { Component } from 'react';

class Image extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="Image">
        <img
          src={this.props.smallPic}
          style={{
            display: 'block',
            margin: 'auto',
            maxWidth: '100%',
            maxHeight: '400px',
          }}
          alt={this.props.alt}
          onClick={() => {
            this.props.handleImageBox(true, this.props.url);
          }}
        />
      </div>
    );
  }
}

export default Image;