export const translations = {
  sk: {
    technologie: "technologie",
    Technológie: "Technológie",
    produkty: "produkty",
    Produkty: "Produkty",
    kontakt: "kontakt",
    Kontakt: "Kontakt",
    "Firma Kovo Velič vznikla v roku 1995. Sme rodinná firma zameraná na presnú strojársku výrobu. V súčastnosti zamestnávame 27 zamestnancov.":
      "Firma Kovo Velič vznikla v roku 1995. Sme rodinná firma zameraná na presnú strojársku výrobu. V súčastnosti zamestnávame 27 zamestnancov.",
    "Výrobný program našej firmy je zameraný na kusovú a malosériovú výrobu upínacieho náradia, meradiel a súčiastok podľa požiadaviek zákazníka. V záujme zvyšovania konkurencieschopnosti, neustále obnovujeme náš strojný park najmodernejšími technológiami. Poskytujeme služby v oblasti CNC frézovania, sústruženia, brúsenia ozubenia, disponujeme viacerymi brúskami na plocho, guľato a taktiež dvomi súradnicovými brúskami Hauser.":
      "Výrobný program našej firmy je zameraný na kusovú a malosériovú výrobu upínacieho náradia, meradiel a súčiastok podľa požiadaviek zákazníka. V záujme zvyšovania konkurencieschopnosti, neustále obnovujeme náš strojný park najmodernejšími technológiami. Poskytujeme služby v oblasti CNC frézovania, sústruženia, brúsenia ozubenia, disponujeme viacerymi brúskami na plocho, guľato a taktiež dvomi súradnicovými brúskami Hauser.",
    "O nás": "O nás",
    "Je nám to ľúto ale váš prehliadač nepodporuje prehrávanie videa. Nevadí, video si môžete":
      "Je nám to ľúto ale váš prehliadač nepodporuje prehrávanie videa. Nevadí, video si môžete",
    " stiahnuť tu ": " stiahnuť tu ",
    "a spustiť vo svojom obľúbenom prehliadači.":
      "a spustiť vo svojom obľúbenom prehliadači.",
    "certifikát kvality": "certifikát kvality",
    "Certifikát kvality ISO 9001": "Certifikát kvality ISO 9001",
    "Naša práca": "Naša práca",
    "Ďaľšie produkty": "Ďaľšie produkty",
    Frézovanie: "Frézovanie",
    Sústruženie: "Sústruženie",
    Brúsenie: "Brúsenie",
    EDM: "EDM",
    Ostatné: "Ostatné",
    Adresa: "Adresa",
    Slovensko: "Slovensko",
    "Fakturačné údaje": "Fakturačné údaje",
    IČO: "IČO",
    DIČ: "DIČ",
    "IČ DPH": "IČ DPH",
    "Kontaktné udaje firmy KOVO Velič": "Kontaktné udaje firmy KOVO Velič",
    "Produktové portfólio firmy KOVO Velič.":
      "Produktové portfólio firmy KOVO Velič.",
    "Technológie, ktorými disponujeme vo firme KOVO Velič.":
      "Technológie, ktorými disponujeme vo firme KOVO Velič.",
    Cookies: "Cookies",
    "Súbory cookie": "Súbory cookie",
    "S cieľom zabezpečiť riadne fungovanie tejto webovej lokality ukladáme niekedy na vašom zariadení malé dátové súbory, tzv. cookie. Je to bežná prax väčšiny veľkých webových lokalít.":
      "S cieľom zabezpečiť riadne fungovanie tejto webovej lokality ukladáme niekedy na vašom zariadení malé dátové súbory, tzv. cookie. Je to bežná prax väčšiny veľkých webových lokalít.",
    "Čo sú súbory cookie?": "Čo sú súbory cookie?",
    "Súbor cookie je malý textový súbor, ktorý webová lokalita ukladá vo vašom počítači alebo mobilnom zariadení pri jej prehliadaní. Vďaka tomuto súboru si webová lokalita na určitý čas uchováva informácie o vašich krokoch a preferenciách (ako sú prihlasovacie meno, jazyk, veľkosť písma a iné nastavenia zobrazovania), takže ich pri ďalšej návšteve lokality alebo prehliadaní jej jednotlivých stránok nemusíte opätovne uvádzať.":
      "Súbor cookie je malý textový súbor, ktorý webová lokalita ukladá vo vašom počítači alebo mobilnom zariadení pri jej prehliadaní. Vďaka tomuto súboru si webová lokalita na určitý čas uchováva informácie o vašich krokoch a preferenciách (ako sú prihlasovacie meno, jazyk, veľkosť písma a iné nastavenia zobrazovania), takže ich pri ďalšej návšteve lokality alebo prehliadaní jej jednotlivých stránok nemusíte opätovne uvádzať.",
    "Ako používame súbory cookie?": "Ako používame súbory cookie?",
    "Tieto webstránky používajú súbory cookies na zapamätanie si použivateľských nastavení, pre nevyhnutnú funkcionalitu webstránok.":
      "Tieto webstránky používajú súbory cookies na zapamätanie si použivateľských nastavení, pre nevyhnutnú funkcionalitu webstránok.",
    "Ako kontrolovať súbory cookie": "Ako kontrolovať súbory cookie",
    "Súbory cookie môžete kontrolovať alebo zmazať podľa uváženia – podrobnosti si pozrite na stránke aboutcookies.org. Môžete vymazať všetky súbory cookie uložené vo svojom počítači a väčšinu prehliadačov môžete nastaviť tak, aby ste im znemožnili ich ukladanie. V takomto prípade však pravdepodobne budete musieť pri každej návšteve webovej lokality manuálne upravovať niektoré nastavenia a niektoré služby a funkcie nebudú fungovať.":
      "Súbory cookie môžete kontrolovať alebo zmazať podľa uváženia – podrobnosti si pozrite na stránke aboutcookies.org. Môžete vymazať všetky súbory cookie uložené vo svojom počítači a väčšinu prehliadačov môžete nastaviť tak, aby ste im znemožnili ich ukladanie. V takomto prípade však pravdepodobne budete musieť pri každej návšteve webovej lokality manuálne upravovať niektoré nastavenia a niektoré služby a funkcie nebudú fungovať.",
    "Ako odmietnuť používanie súborov cookie":
      "Ako odmietnuť používanie súborov cookie",
    "Používanie súborov cookie je možné nastaviť pomocou Vášho internetového prehliadača. Väčšina prehliadačov súbory cookie automaticky prijíma už vo úvodnom nastavení.":
      "Používanie súborov cookie je možné nastaviť pomocou Vášho internetového prehliadača. Väčšina prehliadačov súbory cookie automaticky prijíma už vo úvodnom nastavení.",
    "Vo svete automotive": "Vo svete automotive",
  },
  en: {
    technologie: "technologies",
    Technológie: "Technologies",
    produkty: "products",
    Produkty: "Products",
    kontakt: "contact",
    Kontakt: "Contact",
    "Firma Kovo Velič vznikla v roku 1995. Sme rodinná firma zameraná na presnú strojársku výrobu. V súčastnosti zamestnávame 27 zamestnancov.":
      "Kovo Velič was established in 1995. We are a family company focused on precise engineering production. We currently employ 27 employees.",
    "Výrobný program našej firmy je zameraný na kusovú a malosériovú výrobu upínacieho náradia, meradiel a súčiastok podľa požiadaviek zákazníka. V záujme zvyšovania konkurencieschopnosti, neustále obnovujeme náš strojný park najmodernejšími technológiami. Poskytujeme služby v oblasti CNC frézovania, sústruženia, brúsenia ozubenia, disponujeme viacerymi brúskami na plocho, guľato a taktiež dvomi súradnicovými brúskami Hauser.":
      "The production program of our company is focused on piece and small series production of clamping tools, gauges and components according to customer requirements. In order to increase competitiveness, we are constantly renewing our machinery with the latest technologies. We provide services in the field of CNC milling, turning, gear grinding, we have several surface grinders, round grinders and also two Hauser coordinate grinders.",
    "O nás": "About us",
    "Je nám to ľúto ale váš prehliadač nepodporuje prehrávanie videa. Nevadí, video si môžete":
      "We're sorry, but your browser does not support video playback. Never mind, you can watch the video",
    " stiahnuť tu ": " here ",
    "a spustiť vo svojom obľúbenom prehliadači.":
      "and watch in your favorite browser.",
    "certifikát kvality": "quality certificate",
    "Certifikát kvality ISO 9001": "Quality certificate ISO 9001",
    "Naša práca": "Our work",
    "Ďaľšie produkty": "Other products",
    Frézovanie: "Milling",
    Sústruženie: "Turning",
    Brúsenie: "Grinding",
    EDM: "EDM",
    Ostatné: "Miscellaneous",
    Adresa: "Address",
    Slovensko: "Slovakia",
    "Fakturačné údaje": "Billing information",
    IČO: "Company ID",
    DIČ: "Tax ID",
    "IČ DPH": "VAT",
    "Kontaktné udaje firmy KOVO Velič": "Contact details of KOVO Velič",
    "Produktové portfólio firmy KOVO Velič.":
      "Product portfolio of KOVO Velič.",
    "Technológie, ktorými disponujeme vo firme KOVO Velič.":
      "Technologies we have at KOVO Velič.",
    Cookies: "Cookies",
    "Súbory cookie": "Cookies",
    "S cieľom zabezpečiť riadne fungovanie tejto webovej lokality ukladáme niekedy na vašom zariadení malé dátové súbory, tzv. cookie. Je to bežná prax väčšiny veľkých webových lokalít.":
      "In order to ensure the proper functioning of this website, we sometimes store small data files on your device, so-called cookie. This is a common practice for most large websites.",
    "Čo sú súbory cookie?": "What are cookies?",
    "Súbor cookie je malý textový súbor, ktorý webová lokalita ukladá vo vašom počítači alebo mobilnom zariadení pri jej prehliadaní. Vďaka tomuto súboru si webová lokalita na určitý čas uchováva informácie o vašich krokoch a preferenciách (ako sú prihlasovacie meno, jazyk, veľkosť písma a iné nastavenia zobrazovania), takže ich pri ďalšej návšteve lokality alebo prehliadaní jej jednotlivých stránok nemusíte opätovne uvádzať.":
      "A cookie is a small text file that a website stores on your computer or mobile device when you browse it. This file keeps your website informed of your steps and preferences (such as login, language, font size, and other display settings) for a period of time, so you don't have to re-enter them the next time you visit or browse the site.",
    "Ako používame súbory cookie?": "How do we use cookies?",
    "Tieto webstránky používajú súbory cookies na zapamätanie si použivateľských nastavení, pre nevyhnutnú funkcionalitu webstránok.":
      "These websites use cookies to remember user settings, for the necessary functionality of the website.",
    "Ako kontrolovať súbory cookie": "How to control cookies",
    "Súbory cookie môžete kontrolovať alebo zmazať podľa uváženia – podrobnosti si pozrite na stránke aboutcookies.org. Môžete vymazať všetky súbory cookie uložené vo svojom počítači a väčšinu prehliadačov môžete nastaviť tak, aby ste im znemožnili ich ukladanie. V takomto prípade však pravdepodobne budete musieť pri každej návšteve webovej lokality manuálne upravovať niektoré nastavenia a niektoré služby a funkcie nebudú fungovať.":
      "You can control or delete cookies at your discretion - see aboutcookies.org for details. You can clear all cookies stored on your computer, and you can set most browsers to prevent them from being stored. However, in this case, you may need to manually adjust some settings each time you visit the website, and some services and features may not work.",
    "Ako odmietnuť používanie súborov cookie":
      "How to refuse the use of cookies",
    "Používanie súborov cookie je možné nastaviť pomocou Vášho internetového prehliadača. Väčšina prehliadačov súbory cookie automaticky prijíma už vo úvodnom nastavení.":
      "The use of cookies can be set using your internet browser. Most browsers automatically accept cookies by default.",
    "Vo svete automotive": "In the automotive world",
  },
  de: {
    technologie: "technologien",
    Technológie: "Technologien",
    produkty: "produkte",
    Produkty: "Produkte",
    kontakt: "impressum",
    Kontakt: "Impressum",
    "Firma Kovo Velič vznikla v roku 1995. Sme rodinná firma zameraná na presnú strojársku výrobu. V súčastnosti zamestnávame 27 zamestnancov.":
      "Das Unternehmen Kovo Velič wurde 1995 gegründet. Wir sind ein Familienunternehmen, das sich auf die Produktion von Feinmechanik konzentriert. Wir beschäftigen derzeit 27 Mitarbeiter.",
    "Výrobný program našej firmy je zameraný na kusovú a malosériovú výrobu upínacieho náradia, meradiel a súčiastok podľa požiadaviek zákazníka. V záujme zvyšovania konkurencieschopnosti, neustále obnovujeme náš strojný park najmodernejšími technológiami. Poskytujeme služby v oblasti CNC frézovania, sústruženia, brúsenia ozubenia, disponujeme viacerymi brúskami na plocho, guľato a taktiež dvomi súradnicovými brúskami Hauser.":
      "Das Produktionsprogramm unseres Unternehmens konzentriert sich auf die Stück- und Kleinserienfertigung von Spannwerkzeugen, Messgeräten und Bauteilen nach Kundenwunsch. Um die Wettbewerbsfähigkeit zu steigern, erneuern wir unsere Maschinen ständig mit den neuesten Technologien. Wir bieten Dienstleistungen im Bereich CNC-Fräsen, Drehen, Zahnradschleifen an, wir haben mehrere Flachschleifer, Rundschleifer und auch zwei Hauser-Koordinatenschleifer.",
    "O nás": "Unsere Firma",
    "Je nám to ľúto ale váš prehliadač nepodporuje prehrávanie videa. Nevadí, video si môžete":
      "Ihr Browser unterstützt leider keine Videowiedergabe. Egal, Sie können das Video ",
    " stiahnuť tu ": " hier ansehen ",
    "a spustiť vo svojom obľúbenom prehliadači.":
      "und in Ihrem Lieblingsbrowser ausführen.",
    "certifikát kvality": "qualitätszertifikat",
    "Certifikát kvality ISO 9001": "Qualitätszertifikat ISO 9001",
    "Naša práca": "Unsere Arbeit",
    "Ďaľšie produkty": "Andere Produkte",
    Frézovanie: "Mahlen",
    Sústruženie: "Drehen",
    Brúsenie: "Schleifen",
    EDM: "EDM",
    Ostatné: "Sonstiges",
    Adresa: "Adresse",
    Slovensko: "Slowakei",
    "Fakturačné údaje": "Rechnungsdaten",
    IČO: "Firmen ID",
    DIČ: "Steuernummer",
    "IČ DPH": "USt-IdNr",
    "Kontaktné udaje firmy KOVO Velič": "Kontaktdaten von KOVO Velič",
    "Produktové portfólio firmy KOVO Velič.":
      "Produktportfolio von KOVO Velič.",
    "Technológie, ktorými disponujeme vo firme KOVO Velič.":
      "Technologien, die wir bei KOVO Velič haben.",
    Cookies: "Cookies",
    "Súbory cookie": "Cookies",
    "S cieľom zabezpečiť riadne fungovanie tejto webovej lokality ukladáme niekedy na vašom zariadení malé dátové súbory, tzv. cookie. Je to bežná prax väčšiny veľkých webových lokalít.":
      "Um das ordnungsgemäße Funktionieren dieser Website zu gewährleisten, speichern wir manchmal kleine Datendateien auf Ihrem Gerät, sogenannte Plätzchen. Dies ist eine gängige Praxis für die meisten großen Websites.",
    "Čo sú súbory cookie?": "Was sind Cookies?",
    "Súbor cookie je malý textový súbor, ktorý webová lokalita ukladá vo vašom počítači alebo mobilnom zariadení pri jej prehliadaní. Vďaka tomuto súboru si webová lokalita na určitý čas uchováva informácie o vašich krokoch a preferenciách (ako sú prihlasovacie meno, jazyk, veľkosť písma a iné nastavenia zobrazovania), takže ich pri ďalšej návšteve lokality alebo prehliadaní jej jednotlivých stránok nemusíte opätovne uvádzať.":
      "Ein Cookie ist eine kleine Textdatei, die eine Website beim Durchsuchen auf Ihrem Computer oder Mobilgerät speichert. Diese Datei hält Ihre Website für einen bestimmten Zeitraum über Ihre Schritte und Einstellungen (wie Anmeldung, Sprache, Schriftgröße und andere Anzeigeeinstellungen) auf dem Laufenden, sodass Sie sie beim nächsten Besuch oder Durchsuchen der Website nicht erneut eingeben müssen.",
    "Ako používame súbory cookie?": "Wie verwenden wir Cookies?",
    "Tieto webstránky používajú súbory cookies na zapamätanie si použivateľských nastavení, pre nevyhnutnú funkcionalitu webstránok.":
      "Diese Websites verwenden Cookies, um Benutzereinstellungen zu speichern, die erforderliche Funktionalität der Website zu gewährleisten.",
    "Ako kontrolovať súbory cookie": "So steuern Sie Cookies",
    "Súbory cookie môžete kontrolovať alebo zmazať podľa uváženia – podrobnosti si pozrite na stránke aboutcookies.org. Môžete vymazať všetky súbory cookie uložené vo svojom počítači a väčšinu prehliadačov môžete nastaviť tak, aby ste im znemožnili ich ukladanie. V takomto prípade však pravdepodobne budete musieť pri každej návšteve webovej lokality manuálne upravovať niektoré nastavenia a niektoré služby a funkcie nebudú fungovať.":
      "Sie können Cookies nach eigenem Ermessen steuern oder löschen. Weitere Informationen finden Sie unter aboutcookies.org. Sie können alle auf Ihrem Computer gespeicherten Cookies löschen und die meisten Browser so einstellen, dass sie nicht gespeichert werden. In diesem Fall müssen Sie möglicherweise einige Einstellungen bei jedem Besuch der Website manuell anpassen, und einige Dienste und Funktionen funktionieren möglicherweise nicht.",
    "Ako odmietnuť používanie súborov cookie":
      "So verweigern Sie die Verwendung von Cookies",
    "Používanie súborov cookie je možné nastaviť pomocou Vášho internetového prehliadača. Väčšina prehliadačov súbory cookie automaticky prijíma už vo úvodnom nastavení.":
      "Die Verwendung von Cookies kann über Ihren Internetbrowser eingestellt werden. Die meisten Browser akzeptieren Cookies standardmäßig automatisch.",
    "Vo svete automotive": "In der Welt des Automobils",
  },
};
