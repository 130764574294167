import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useLng } from "../context/LngProvider";
import { translations } from "../assets/translations";
import { languages } from "../assets/languages";

export const Cookies = () => {
  const { lng, setLng } = useLng();
  const { lang } = useParams();

  useEffect(() => {
    if (lang !== lng && languages.indexOf(lang) >= 0) {
      setLng(lang);
    }
  }, [lang]);

  return (
    <div id="Cookies">
      <Helmet>
        <title>KOVO Velič | {translations[lng]["Cookies"]}</title>
      </Helmet>
      <b>{translations[lng]["Súbory cookie"]}</b>
      <p>
        {
          translations[lng][
            "S cieľom zabezpečiť riadne fungovanie tejto webovej lokality ukladáme niekedy na vašom zariadení malé dátové súbory, tzv. cookie. Je to bežná prax väčšiny veľkých webových lokalít."
          ]
        }
      </p>
      <b>{translations[lng]["Čo sú súbory cookie?"]}</b>
      <p>
        {
          translations[lng][
            "Súbor cookie je malý textový súbor, ktorý webová lokalita ukladá vo vašom počítači alebo mobilnom zariadení pri jej prehliadaní. Vďaka tomuto súboru si webová lokalita na určitý čas uchováva informácie o vašich krokoch a preferenciách (ako sú prihlasovacie meno, jazyk, veľkosť písma a iné nastavenia zobrazovania), takže ich pri ďalšej návšteve lokality alebo prehliadaní jej jednotlivých stránok nemusíte opätovne uvádzať."
          ]
        }
      </p>

      <b>{translations[lng]["Ako používame súbory cookie?"]}</b>
      <p>
        {
          translations[lng][
            "Tieto webstránky používajú súbory cookies na zapamätanie si použivateľských nastavení, pre nevyhnutnú funkcionalitu webstránok."
          ]
        }
      </p>
      <b>{translations[lng]["Ako kontrolovať súbory cookie"]}</b>
      <p>
        {
          translations[lng][
            "Súbory cookie môžete kontrolovať alebo zmazať podľa uváženia – podrobnosti si pozrite na stránke aboutcookies.org. Môžete vymazať všetky súbory cookie uložené vo svojom počítači a väčšinu prehliadačov môžete nastaviť tak, aby ste im znemožnili ich ukladanie. V takomto prípade však pravdepodobne budete musieť pri každej návšteve webovej lokality manuálne upravovať niektoré nastavenia a niektoré služby a funkcie nebudú fungovať."
          ]
        }
      </p>
      <b>{translations[lng]["Ako odmietnuť používanie súborov cookie"]}</b>
      <p>
        {
          translations[lng][
            "Používanie súborov cookie je možné nastaviť pomocou Vášho internetového prehliadača. Väčšina prehliadačov súbory cookie automaticky prijíma už vo úvodnom nastavení."
          ]
        }
      </p>
    </div>
  );
};
