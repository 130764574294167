import React, { Fragment } from "react";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import products from "../assets/products";
import { translations } from "../assets/translations";
import { useLng } from "../context/LngProvider";

export const ProductCarousel = () => {
  const { lng } = useLng();
  const history = useHistory();

  return (
    <Fragment>
      <h3 className="homeTitle">
        {(translations &&
          translations[lng] &&
          translations[lng]["Naša práca"]) ||
          "Naša práca"}
      </h3>
      <Carousel
        className="carouselStyle"
        indicators={false}
        interval={5000}
        controls={true}
      >
        {products.slice(0, 5).map((product, index) => {
          if (index === 4) {
            return (
              <Carousel.Item key={`${product}-${index}`}>
                <img
                  className="blurredImage"
                  src={product}
                  alt={`vyrobok ${index}`}
                />
                <a
                  className="blurredLink"
                  onClick={() =>
                    history.push(`/${lng}/${translations[lng]["produkty"]}`)
                  }
                >
                  {(translations &&
                    translations[lng] &&
                    translations[lng]["Ďaľšie produkty"]) ||
                    "Ďaľšie produkty"}
                </a>
              </Carousel.Item>
            );
          } else {
            return (
              <Carousel.Item key={`${product}-${index}`}>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={product.substring(0, 49) + product.substring(63)}
                  alt={`vyrobok ${index}`}
                />
              </Carousel.Item>
            );
          }
        })}
      </Carousel>
    </Fragment>
  );
};
