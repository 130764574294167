import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useLng } from "../context/LngProvider";
import Map from "./Map";
import { translations } from "../assets/translations";
import { languages } from "../assets/languages";

export const Contact = () => {
  const { lng, setLng } = useLng();
  const { lang } = useParams();

  useEffect(() => {
    if (lang !== lng && languages.indexOf(lang) >= 0) {
      setLng(lang);
    }
  }, [lang]);

  return (
    <Fragment>
      <Helmet>
        <title>KOVO Velič | {translations[lng]["Kontakt"]}</title>
        <meta name="description" content={translations[lng][""]} />
      </Helmet>
      <div id="Contact">
        <div className="contactField">
          <h2>
            {(translations &&
              translations[lng] &&
              translations[lng]["Adresa"]) ||
              "Adresa"}
          </h2>
          <p>Brvnište 210</p>
          <p>018 12 Brvnište</p>
          <p>
            {(translations &&
              translations[lng] &&
              translations[lng]["Slovensko"]) ||
              "Slovensko"}
          </p>
          <h2>
            {(translations &&
              translations[lng] &&
              translations[lng]["Fakturačné údaje"]) ||
              "Fakturačné údaje"}
          </h2>
          <p>
            {(translations && translations[lng] && translations[lng]["IČO"]) ||
              "IČO"}
            : 46442723
          </p>
          <p>
            {(translations && translations[lng] && translations[lng]["DIČ"]) ||
              "DIČ"}
            : 2023385639
          </p>
          <p>
            {(translations &&
              translations[lng] &&
              translations[lng]["IČ DPH"]) ||
              "IČ DPH"}
            : SK2023385639
          </p>
        </div>
        <div className="contactField">
          <h2>
            {(translations &&
              translations[lng] &&
              translations[lng]["Kontakt"]) ||
              "Kontakt"}
          </h2>
          <p>
            Email: <a href="mailto:velic@kovovelic.sk">velic@kovovelic.sk</a>
          </p>
          <h2>English contact 🇬🇧</h2>
          <p>
            MSc. Lenka Veličová <a href="tel:+421908785666">+421 908 785 666</a>
          </p>
        </div>
        <Map />
      </div>
    </Fragment>
  );
};
