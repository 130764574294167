import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import products from "../assets/products";
import ImageBox from "./ImageBox";
import { useLng } from "../context/LngProvider";
import { languages } from "../assets/languages";
import { translations } from "../assets/translations";

export const Products = () => {
  const { lng, setLng } = useLng();
  const { lang } = useParams();

  useEffect(() => {
    if (lang !== lng && languages.indexOf(lang) >= 0) {
      setLng(lang);
    }
  }, [lang]);

  const [state, setState] = useState({
    showImageBox: false,
    showUrl: "",
    products,
  });

  function handleImageBox(open, url) {
    let fullUrl = url.substring(0, 49) + url.substring(63);
    let newState = Object.assign({}, state);
    newState.showImageBox = open;
    newState.showUrl = fullUrl;
    setState(newState);
  }

  return (
    <Fragment>
      <Helmet>
        <title>KOVO Velič | {translations[lng]["Produkty"]}</title>
        <meta
          name="description"
          content={translations[lng]["Produktové portfólio firmy KOVO Velič."]}
        />
      </Helmet>
      <h2 className="productHeader">
        {(translations && translations[lng] && translations[lng]["Produkty"]) ||
          "Produkty"}
      </h2>
      <div id="Products">
        {products.map((product, i) => (
          <img
            key={i}
            src={product}
            alt={product.alt || "výrobok"}
            className="product"
            onClick={() => {
              handleImageBox(true, product);
            }}
          />
        ))}
        <ImageBox
          show={state.showImageBox}
          onHide={() => handleImageBox(false, "")}
          handleImageBox={handleImageBox}
          url={state.showUrl}
        />
      </div>
    </Fragment>
  );
};
