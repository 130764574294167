import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.onMarkerClick = this.onMarkerClick.bind(this)
  }

  onMarkerClick() {
    window.open("https://www.google.com/maps/dir//49.2152771,18.4240268/@49.215277,18.424027,16z?hl=sk-SK", "_blank")
  }

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={17}
        className="map"
        initialCenter={{
          lat: 49.215333,
          lng: 18.423924,
        }}>
        <Marker
          title={'Naviguj'}
          name={'KOVO Velič'}
          onClick={this.onMarkerClick}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAGRfCTRFc0Ja3QMthjyPtWEcHiCl8LJ3M'
})(MapContainer)