import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import machines from "../assets/machines";
import Image from "./Image";
import ImageBox from "./ImageBox";
import NotFound from "./NotFound";
import TechNav from "./TechNav";
import { useLng } from "../context/LngProvider";
import { languages } from "../assets/languages";
import { translations } from "../assets/translations";

export const Technologies = () => {
  const { lng, setLng } = useLng();
  const { lang } = useParams();
  useEffect(() => {
    if (lang !== lng && languages.indexOf(lang) >= 0) {
      setLng(lang);
    }
  }, [lang]);
  const [tech, setTech] = useState("frezovanie");
  const [state, setState] = useState({
    showImageBox: false,
    showUrl: "",
    machines,
  });

  function handleImageBox(open, url) {
    let newState = Object.assign({}, state);
    newState.showImageBox = open;
    newState.showUrl = url;
    setState(newState);
  }

  if (!machines[tech || "frezovanie"]) {
    return <NotFound />;
  } else {
    return (
      <div id="Technologies">
        <Helmet>
          <title>KOVO Velič | {translations[lng]["Technológie"]}</title>
          <meta
            name="description"
            content={
              translations[lng][
                "Technológie, ktorými disponujeme vo firme KOVO Velič."
              ]
            }
          />
        </Helmet>
        <TechNav tech={tech || "frezovanie"} setTech={setTech} />
        <div className="machineWrapper">
          {state.machines[tech || "frezovanie"].map((machine, index) => (
            <div className="machineBox" key={tech + "-machine-" + index}>
              <Image
                className="imageBox"
                url={machine.pic}
                smallPic={machine.smallPic}
                alt={machine.name}
                handleImageBox={handleImageBox}
              />
              <p>{machine.name[lang]}</p>
            </div>
          ))}
        </div>
        <ImageBox
          show={state.showImageBox}
          onHide={() => handleImageBox(false, "")}
          handleImageBox={handleImageBox}
          url={state.showUrl}
        />
      </div>
    );
  }
};
